import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://ad8334f6bc1d4d1b72a0a1dbb38bfa21@o4507943962279936.ingest.de.sentry.io/4507943971651664",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});